import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

export const MonetateContext = createContext();

export function MonetateProvider({ experiences, children }) {
  return <MonetateContext.Provider value={{ experiences }}>{children}</MonetateContext.Provider>;
}

MonetateProvider.propTypes = {
  experiences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  children: PropTypes.node.isRequired,
};

export const findExperience = (experiences, name) =>
  experiences ? experiences.find(item => item.experienceName.includes(name)) : null;

export const useExperience = name => {
  const { experiences } = useContext(MonetateContext);
  return findExperience(experiences, name);
};

export const extractExperiences = (req, devExperiences) => {
  let experiences = process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === "stubs" ? devExperiences : [];
  if (req?.headers["x-experiences-json"]) {
    try {
      experiences = JSON.parse(Buffer.from(req.headers["x-experiences-json"], "base64").toString("utf-8"));
    } catch (err) {
      console.error("Failed parsing x-experiences-json", err);
    }
  }
  return experiences;
};

export const devExperiences = [
  {
    impressionId: null,
    experienceName: "GH | AB-4279 | Streamline JL giftcard hero section",
    variantLabel: "Experiment",
    parameters: { feature: "experiment" },
  },
];
